*
{
    margin: 0;
    padding: 0;
}

html,
body
{
    overflow: hidden;
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}

/*--------------------------------------Loading Bar--------------------------------------*/
.progress-bar-container {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#progress-bar {
    width: 30%;
    margin-top: 0.5%;
    height: 2%;
}

#label-bar1 {
    color: white;
    padding-bottom: 20px;
    font-size: 2rem;
}

#label-bar2 {
    color: white;
    padding-bottom: 50px;
    font-size: 2rem;
}

/*--------------------------------------Button--------------------------------------*/
#btn{
    width: 129px;
    height: 32px;
    font-family: 'Roboto', sans-serif;
    font-size: 11px;
    text-transform: uppercase;
    letter-spacing: 2.5px;
    font-weight: 500;
    padding: 10px;
    text-decoration: none;
    background-color: #2EE59D;
    color: black;
    border: none;
    border-radius: 45px;
    cursor: pointer;
    outline: none;
}

/*--------------------------------------Object text--------------------------------------*/
/*.points-of-interest {*/
/*    position: absolute;*/
/*    top: 50%;*/
/*    left: 50%;*/
/*}*/


/*.label {*/
/*    width: 200px;*/
/*    height: 40px;*/
/*    top: -20px;*/
/*    left: -20px;*/
/*    padding: 20px;*/
/*    border-radius: 4px;*/
/*    background: #00000077;*/
/*    color: white;*/
/*    font-family: 'Montserrat', sans-serif;*/
/*    text-align: center;*/
/*    line-height: 40px;*/
/*    font-weight: 100;*/
/*    font-size: 14px;*/
/*    opacity: 0;*/
/*    transition: opacity 0.4s;*/
/*}*/


/*@keyframes fade {*/
/*    0% {opacity: 0;}*/
/*    50% {opacity: 1;}*/
/*    100% {opacity: 0;}*/
/*}*/


/*--------------------------------------Contact Page--------------------------------------*/
#contact-container {
    background-image: linear-gradient(to right, #1c1d25, gray);
    background-color: #1c1d25;
    font-family: 'Montserrat', sans-serif;
}

.contact-form{
    max-width: 600px;
    margin: auto;
    background: none;
    align-content: center;
}

.contact-form-text {
    display: block;
    width: 100%;
    box-sizing: border-box;
    margin: 16px 0;
    border: 0;
    outline: none;
    transition: 0.5s;
}

.contact-form-text:hover{
    box-shadow: 0 0 10px 4px #ffcb9a;
}
h2 {
    font-size: 50px;
    color:#ffcb9a;
    text-align: center;
}

textarea.contact-form-text{
    resize: none;
    height: 120px;
}

.contact-form-button{
    width: 200px;
    height: 60px;
    background:none;
    border: 4px solid;
    color: #ffcb9a;
    font-weight: 700;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 16px;
    position: relative;
}

.contact-form-button::before,.contact-form-button::after {
    content: "";
    position: absolute;
    width: 14px;
    height:4px;
    background: #4f4f53;
    transform: skewX(50deg);
    transition: .4s linear;
}

.contact-form-button::before {
    top: -4px;
    left: 10%;
}

.contact-form-button::after {
    bottom: -4px;
    right: 10%;
}

.contact-form-button:hover::before {
    left: 80%;
}

.contact-form-button:hover::after {
    right: 80%;
}